export const DICTIONARY = {
    // english
    en: {
        day: {
            one: 'day',
            many: 'days',
        }
    },
    // french
    fr: {
        month: {
            'January': 'Janvier',
            'February': 'Février',
            'March': 'Mars',
            'April': 'Avril',
            'May': 'Mai',
            'June': 'Juin',
            'July': 'Juillet',
            'August': 'Août',
            'September': 'Septembre',
            'October': 'Octobre',
            'November': 'Novembre',
            'December': 'Décembre',
        },
        day: {
            one: 'jour',
            many: 'jours',
        }
    },
    // chinese
    zh: {
        month: {
            'January': '一月',
            'February': '二月',
            'March': '三月',
            'April': '四月',
            'May': '五月',
            'June': '六月',
            'July': '七月',
            'August': '八月',
            'September': '九月',
            'October': '十月',
            'November': '十一月',
            'December': '十二月',
        },
        day: {
            one: '天',
            many: '天',
        }
    },
    // kazakh
    kk: {
        month: {
            'January': 'қаңтарындағы',
            'February': 'ақпанындағы',
            'March': 'наурызындағы',
            'April': 'сәуіріндегі',
            'May': 'мамырындағы',
            'June': 'маусымындағы',
            'July': 'шілдесіндегі',
            'August': 'тамызындағы',
            'September': 'қыркүйегіндегі',
            'October': 'қазанындағы',
            'November': 'қарашасындағы',
            'December': 'желтоқсанындағы',
        },
        day: {
            one: 'күннен',
            many: 'күннен',
        }
    },
    // russian
    ru: {
        month: {
            'January': 'января',
            'February': 'февраля',
            'March': 'марта',
            'April': 'апреля',
            'May': 'мая',
            'June': 'июня',
            'July': 'июля',
            'August': 'августа',
            'September': 'сентября',
            'October': 'октября',
            'November': 'ноября',
            'December': 'декабря',
        },
        day: {
            one: 'дня',
            many: 'дней',
        }
    },
    de: {
        month: {
            'January': '',
            'February': '',
            'March': '',
            'April': '',
            'May': '',
            'June': '',
            'July': '',
            'August': '',
            'September': '',
            'October': '',
            'November': '',
            'December': '',
        },
        day: {
            one: 'Tage',
            many: 'Tage',
        }
    },
    cs: {
        month: {
            'January': '',
            'February': '',
            'March': '',
            'April': '',
            'May': '',
            'June': '',
            'July': '',
            'August': '',
            'September': '',
            'October': '',
            'November': '',
            'December': '',
        },
        day: {
            one: 'dny',
            many: 'dní',
        }
    },
};

export const EXTRA_DICTIONARY = {
    'ru': {
        numeralEnds: {
            byNumbers: new Map([
                [[11, 12, 13, 14], {
                    end: '-и',
                    day: DICTIONARY.ru.day.many,
                }],
            ]),
            byNumberEnds: new Map([
                [[1], {
                    end: '-го',
                    day: DICTIONARY.ru.day.one,
                }],
                [[2, 3, 4], {
                    end: '-х',
                    day: DICTIONARY.ru.day.many,
                }],
                [[5, 6, 7, 8, 9, 0], {
                    end: '-и',
                    day: DICTIONARY.ru.day.many,
                }],
            ]),
        },
    }
};
